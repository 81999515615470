<template>
  <div class="regist gk-bg">
    <div style="width:400px;margin:0 auto;padding-top:20%;">
      <el-form label-width="80px">
        <el-form-item>
          <el-input v-model="userInfo.userEmail" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="userInfo.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="comfirmpwd" placeholder="确认密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入手机号" maxlength="11" v-model="userInfo.userPhone">
            <template slot="prepend">+86</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入内容" v-model="phonecode">
            <template slot="append">
              <div class="gk-pointer" v-show="showcode" @click="getcode">获取验证码</div>
              <div v-show="!showcode">重新发送({{count}})</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width:50%" type="primary" @click="regist">注  册</el-button>
          <el-link style="float:right;" type="primary" :underline="false" @click="tologin">使用已有帐户登录</el-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      userInfo: {},
      comfirmpwd: '',
      count: '',
      showcode: true,
      phonecode: ''
    }
  },
  methods:{}
}
</script>
<style scoped>
.gk-bg{
  width:100%;
  height:100%;
  background: url('../assets/gk-bg.png') no-repeat;
  background-size: 100% 100%;
}
</style>